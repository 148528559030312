import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type Props = {
  title?: string;
  width: number;
  height: number;
}

const chart_style = {
    fontFamily: "Ubuntu, Roboto, sans-serif, serif",
}

const build_age = 3;

const data = [
  {
    component: 'cpu',
    newness: build_age - 3,
  },
  {
    component: 'gpu',
    newness: build_age - 2.5,
  },
  {
    component: 'psu',
    newness: build_age - 3,
  },
  {
    component: 'case',
    newness: build_age - 3,
  },
  {
    component: 'case_fans',
    newness: build_age - 1.2,
  },
  {
    component: 'motherboard',
    newness: build_age - 3,
  },
  {
    component: 'storage',
    newness: build_age - 3,
  },
  {
    component: 'ram',
    newness: build_age - 3,
  },
  {
    component: 'cpu_cooler',
    newness: build_age - 3,
  },
];

const BitLotBarGraph = (props: Props) => {
    return (
        <main style={chart_style}>
          <Grid container sx={{width: props.width, height: props.height}} m={2}>
              <Grid item xs={12}>
                {!!props.title &&
                  <Typography variant={'h6'} sx={{textAlign: 'center'}}>
                    {props.title}
                  </Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer width={'100%'} height={'100%'}>
                  <BarChart
                      data={data}
                      margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                      }}
                  >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="component" />
                      <YAxis domain={[0, 4]} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="newness" fill="#39B54A" background={{ fill: '#eee' }} activeBar={<Rectangle stroke="#39B54A" />} />
                    </BarChart>
                  </ResponsiveContainer>
              </Grid>
            </Grid>
        </main>
    );
}

export default BitLotBarGraph;
